var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppTable",
    _vm._g(
      _vm._b(
        {
          ref: "table",
          attrs: {
            fields: _vm.fields,
            items: _vm.computedItems,
            "primary-key": "id"
          },
          scopedSlots: _vm._u(
            [
              {
                key: "cell(interact)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("label", [
                      _c("input", {
                        attrs: { type: "checkbox", disabled: item.status },
                        domProps: { checked: _vm.isSelected(item) },
                        on: {
                          change: function($event) {
                            return _vm.toggleSelected(item)
                          }
                        }
                      })
                    ])
                  ]
                }
              },
              {
                key: "cell(id)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm.deleteConfirmations[item.id]
                      ? _c("div", [
                          _c("span", [
                            _vm._v("Do you want to delete the scrim?")
                          ]),
                          _c("div", { staticClass: "mt-1" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary mr-2",
                                attrs: { role: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.hideDeleteConfirmation(item)
                                  }
                                }
                              },
                              [_vm._v("No")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-warning ml-2",
                                attrs: { role: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteScrim(item)
                                  }
                                }
                              },
                              [_vm._v("Yes")]
                            )
                          ])
                        ])
                      : _c(
                          "div",
                          [
                            _c("div", { staticClass: "mb-2" }, [
                              _vm._v(_vm._s(item.id))
                            ]),
                            false
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "btn btn-outline-primary action action--stats",
                                    attrs: { to: "/scrim/" + item.id + "/edit" }
                                  },
                                  [_c("b-icon-pencil"), _vm._v(" Edit ")],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-outline-primary action action--stats",
                                attrs: { role: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.showDeleteConfirmation(item)
                                  }
                                }
                              },
                              [_c("b-icon-trash"), _vm._v(" Delete ")],
                              1
                            )
                          ],
                          1
                        )
                  ]
                }
              },
              {
                key: "cell(created_at)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _c("ScrimVodStatusIndicator", {
                          attrs: { "match-id": item.id }
                        }),
                        _c(
                          "time",
                          {
                            staticClass: "column",
                            attrs: {
                              datetime: _vm._f("isoString")(item.created_at)
                            }
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("localDate")(item.created_at))
                              )
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("localTime")(item.created_at))
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "cell(map_id)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.map_id
                      ? _c("MapCell", {
                          attrs: { id: item.map_id, variant: "col", size: "lg" }
                        })
                      : _c("span", { staticClass: "no-data" }, [_vm._v("N/A")])
                  ]
                }
              },
              {
                key: "cell(team1)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _c(
                          "TeamCell",
                          _vm._b(
                            { attrs: { variant: "row" } },
                            "TeamCell",
                            item.teams[0],
                            false
                          )
                        ),
                        _c("AgentComposition", {
                          attrs: {
                            agents: item.teams[0].composition || [],
                            highlight: _vm.highlightAgentsTeam1,
                            variant: item.teams[0].team_side,
                            size: "sm"
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "cell(score)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("div", { staticClass: "score" }, [
                      _c(
                        "span",
                        {
                          staticClass: "score__result",
                          class:
                            "score__result--" +
                            (item.teams[0].win ? "win" : "loss")
                        },
                        [_vm._v(_vm._s(item.teams[0].rounds_won))]
                      ),
                      _c("span", { staticClass: "score__separator" }, [
                        _vm._v(":")
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "score__result",
                          class:
                            "score__result--" +
                            (item.teams[1].win ? "win" : "loss")
                        },
                        [_vm._v(_vm._s(item.teams[1].rounds_won))]
                      )
                    ])
                  ]
                }
              },
              {
                key: "cell(team2)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _c(
                          "TeamCell",
                          _vm._b(
                            { attrs: { variant: "row" } },
                            "TeamCell",
                            item.teams[1],
                            false
                          )
                        ),
                        _c("AgentComposition", {
                          attrs: {
                            agents: item.teams[1].composition || [],
                            highlight: _vm.highlightAgentsTeam2,
                            variant: item.teams[1].team_side,
                            size: "sm"
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "cell(total_rounds)",
                fn: function(ref) {
                  var item = ref.item
                  return [[_vm._v(" " + _vm._s(item.total_rounds) + " ")]]
                }
              },
              {
                key: "cell(diff)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            Math.abs(
                              item.teams[0].rounds_won -
                                item.teams[1].rounds_won
                            )
                          ) +
                          " "
                      )
                    ]
                  ]
                }
              },
              {
                key: "cell(actions)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    [
                      _c(
                        "div",
                        { staticClass: "column" },
                        [
                          _c("ScrimButtons", { attrs: { "match-id": item.id } })
                        ],
                        1
                      )
                    ]
                  ]
                }
              },
              _vm._l(_vm.$scopedSlots, function(_, slot) {
                return {
                  key: slot,
                  fn: function(scope) {
                    return [_vm._t(slot, null, null, scope)]
                  }
                }
              }),
              {
                key: "row-details",
                fn: function() {
                  return [_vm._v("hello jkgh jk hdkjshf kdsf hjjhello")]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        "AppTable",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }